import styled from 'styled-components'

import { Typography } from 'ui-elements/Typography'

import { BackButton } from '../BackButton'

export const HeaderContainer = styled.header`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 56px;
  padding: 16px 16px 15px 16px;
`

export const FixedHeaderContainer = styled(HeaderContainer)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1;
`

export const BorderBottom = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color('border-primary-subdued')};
`

export const StyledBackButton = styled(BackButton)`
  position: absolute;
  left: 8px;
  top: auto;
  bottom: auto;
`

export const HeaderTitle = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.252px;
`
