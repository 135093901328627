// Auto-generated file created by svgr-core
// Run npm run svg:generate to update
import React, { SVGProps } from 'react'
import { getColorValue } from 'shared/utils/color'

const IconArrowLeft = ({ fill, ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <path
        fill={fill || getColorValue('surface-primary')}
        d="M7 11.995c0 .41.134.751.449 1.072l7.354 7.542c.249.26.545.391.898.391.716 0 1.299-.601 1.299-1.352 0-.38-.153-.711-.41-.982l-6.534-6.681 6.533-6.661c.258-.27.411-.611.411-.982C17 3.591 16.417 3 15.701 3c-.353 0-.65.12-.898.38L7.45 10.924c-.306.32-.449.661-.449 1.072Z"
      />
    </svg>
  )
}

export { IconArrowLeft }
